import { JWTlocalStorageKey } from '../state/atoms'

export default async function api(
  path,
  data,
  method = 'POST',
  headers = {}) {

  return await fetch(`${process.env.NEXT_PUBLIC_API_BASE}/${path}`, {
    method: method,
    headers: setHeaders(headers),
    body: method == 'POST' || method == 'PUT' || method == 'PATCH' ? JSON.stringify(data) : null,
  })
}

export const get = async (path) => {
  return await api(path, null, 'GET')
}


const setHeaders = (headers) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  return { ...headers, ...authHeaders(), ...defaultHeaders }
}

const authHeaders = () => {
  var headers = {}

  const state = localStorage.getItem(JWTlocalStorageKey)
  if (state) {
    const { jwt } = JSON.parse(state)
    if (jwt) {
      headers['Authorization'] = 'Bearer ' + jwt
    }
  }

  return headers
}
