export const makeSubmitOnboardingPayload = (questions, answers) => {
  let { money, email, name, year_started_programming, ...filteredAnswers } = answers
  const answersHydrated = Object.keys(filteredAnswers).map(slug => ({
    slug,
    prompt: questions[slug].prompt,
    value: answers[slug]
  }))

  return {
    user: { email, name, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, money, year_started_programming },
    answers: answersHydrated
  }
}

export const makeSubmitSearchPayload = (_, answers) => {
  let { email, name, ...filteredAnswers } = answers

  return {
    user: { email, name, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    answers: filteredAnswers
  }
}

export const calculateTotal = (questions) => {
  return Object.keys(Object.entries(questions).reduce((acc, [slug, question]) => {
    return !question.omitFromProgress
      ? { ...acc, [slug]: question }
      : acc
  }, {})).length
}

export const filterObject = (obj, callback) => {
  return Object.fromEntries(Object.entries(obj).
    filter(([key, val]) => callback(key, val)))
}

export const moneyToString = (money, availability) => {
  const lines = []

  if (availability.includes('full-time')) {
    lines.push(`$${money * 2}k`)
  }

  if (availability.includes('part-time') ||
    availability.includes('project-based') ||
    availability.includes('flexible')) {
    lines.push(`$${money} / hr`)
  }

  return lines
}

export const locationToString = (region) => {
  if (region.includes('Anywhere')) {
    return ['anywhere']
  }

  return region
}