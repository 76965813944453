import { useEffect, useState, useCallback, useRef } from 'react'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { answersState, activeQuestionsState, questionsOrderState, answeredLastQuestionState } from '../state/atoms'
import { scrollUpSelector } from '../state/selectors'
import { useValidation, useSurvey } from '../lib/hooks'
import { calculateTotal } from '../lib/helpers'
import { Button, NextButton, StyledSubmitButton, TextInput, Flex } from '../styles/shared.css'
import {
  QuestionList, QuestionListItem, QuestionPrompt, QuestionNote,
  ChoiceListItem, ChoiceLabel, ShowMore, ProgressContainer,
  SecondaryAction, SecondaryActionEmphasized,
  VerticalSpaceBetweenPages, ButtonContainer, CustomChoiceTextInput,
  MoneyInputContainer, MoneyTextInput, MoneyTextInputPrefix,
  MoneyTextSelectContainer, MoneyTextSelect, InvalidMoneyErrorMessage,
} from '../styles/questions.css'

const QuestionSubtitle = styled.div`
  margin: 0 0 30px;
`

const ToggleableSubmitButton = styled(StyledSubmitButton)`
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;

  ${({ isVisible }) => isVisible && `
    visibility: visible;
    opacity: 1;
  `}
`

export default function Questions({ questions, pageName, onSubmit, isSubmitting, submitButtonLabel, isEditing }) {
  const setActiveQuestions = useSetRecoilState(activeQuestionsState)
  const answeredLastQuestion = useRecoilValue(answeredLastQuestionState)
  const questionsOrder = useRecoilValue(questionsOrderState)
  const answers = useRecoilValue(answersState)
  const valid = useValidation()

  useEffect(() => {
    if (isEditing) {
      setActiveQuestions(questionsOrder)
    } else if (questionsOrder.length > 0) {
      setActiveQuestions([questionsOrder[0]])
    }
  }, [isEditing, questionsOrder, setActiveQuestions])

  useEffect(() => {
    if (!isSubmitting && answeredLastQuestion && !submitButtonLabel && valid && onSubmit) {
      onSubmit(questions, answers)
    }
  }, [answers, answeredLastQuestion, onSubmit, questions, submitButtonLabel, valid, isSubmitting])

  return <QuestionList id='question-list' pageName={pageName}>
    {Object.keys(questions).map(slug =>
      <Question
        question={questions[slug]}
        key={slug}
        slug={slug}
        total={calculateTotal(questions)}
        isHomepage={pageName === 'home'}
      />
    )}
    <SubmitButton questions={questions}
      answers={answers}
      label={submitButtonLabel}
      isEditing={isEditing}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      isVisible={submitButtonLabel && (isEditing || answeredLastQuestion)}
    />
  </QuestionList>
}

const SubmitButton = ({ questions, answers, label = 'Submit', isEditing, onSubmit, isSubmitting, isVisible }) => {
  const valid = useValidation()

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (onSubmit && valid) {
      onSubmit(questions, answers)
    }
  }

  return <ToggleableSubmitButton
    disabled={isSubmitting}
    autoFocus={!isEditing}
    type='submit'
    value={isSubmitting ? 'Loading...' : `${label} →`}
    isVisible={isVisible}
    onClick={handleSubmit} />
}

const Question = ({ question, slug, total, isHomepage }) => {
  const activeQuestions = useRecoilValue(activeQuestionsState)
  const questionsOrder = useRecoilValue(questionsOrderState)
  const isntFirst = questionsOrder.indexOf(slug) > 0

  if (!activeQuestions.includes(slug)) { return null }

  return <QuestionListItem id={slug}>
    {!!question.subtitle &&
      <QuestionSubtitle>{question.subtitle}</QuestionSubtitle>
    }
    {isntFirst && !question.omitFromProgress &&
      <Progress slug={slug} total={total} />
    }
    <QuestionPrompt>{question.prompt}</QuestionPrompt>
    <Choices slug={slug} question={question} />
    {!!question.note &&
      <QuestionNote>{question.note}</QuestionNote>
    }
    {isntFirst &&
      <BackButton slug={slug} />
    }
    {isHomepage && questionsOrder.indexOf(slug) == 0 && activeQuestions.length == 1 &&
      <SecondaryActionEmphasized href='/search'>I'm an employer ↗</SecondaryActionEmphasized>
    }
  </QuestionListItem>
}

const Choices = (props) => {
  const choiceComponent = () => {
    switch (props.question.type) {
      case 'select-many': return <SelectManyForm {...props} />
      case 'text': return <TextForm {...props} type='text' />
      case 'email': return <TextForm {...props} type='email' />
      case 'url': return <TextForm {...props} type='url' />
      case 'money': return <MoneyForm {...props} />
      default: return <SelectOneForm {...props} />
    }
  }

  return <ul>{choiceComponent()}</ul>
}

const SelectManyForm = ({ slug, question }) => {
  const [answers, setAnswers] = useRecoilState(answersState)
  const answerQuestion = useSurvey()

  return <PaginatedMultiSelect
    allowCustomText={!!question.allowCustomText}
    required={!(question.required === false)}
    choices={question.choices(answers)}
    defaultValues={answers[slug]}
    slug={slug}
    onChange={(selections) => {
      // we handle this manually as to not scroll
      setAnswers({ ...answers, [slug]: selections })
    }}
    onComplete={(selections) => {
      answerQuestion(slug, selections)
    }}
  />
}

export const PaginatedMultiSelect = ({ allowCustomText, required, choices, onChange, onComplete, defaultValues = [], slug, limit = 12 }) => {
  // Increase the limit if pagination doesn't provide much utility.
  if (choices.length <= limit * 1.5) {
    limit *= 1.5
  }

  const answers = useRecoilValue(answersState)
  const activeQuestions = useRecoilValue(activeQuestionsState)
  const [editingCustomChoice, setEditingCustomChoice] = useState(false)
  const [selectedChoices, setSelectedChoices] = useState(defaultValues)
  const [visibleChoices, setVisibleChoices] = useState(choices.slice(0, limit))
  const [customChoices, setCustomChoices] = useState([])
  const [page, setPage] = useState(0)

  const remaining = choices.length - ((page + 1) * limit)
  const showPagination = page >= 0 && remaining > 0
  const isActiveQuestion = activeQuestions.slice(-1) == slug

  useEffect(() => {
    const answer = answers[slug]
    if (answer) {
      setSelectedChoices(answer)
    }
  }, [slug, answers])

  useEffect(() => {
    if (page < 0) {
      setVisibleChoices(choices)
    } else {
      renderPage(page)
    }
  }, [choices, renderPage, page])

  useEffect(() => {
    function handleKeyUp(event) {
      if (event.key === 'Enter' &&
        isActiveQuestion &&
        !editingCustomChoice &&
        (!required || selectedChoices.length > 0)) {
        onComplete(selectedChoices)
      }
    }
    if (isActiveQuestion) {
      window.addEventListener("keyup", handleKeyUp)
    }
    return () => window.removeEventListener("keyup", handleKeyUp)
  }, [selectedChoices, isActiveQuestion, onComplete, required, editingCustomChoice])

  const onSubmit = (event) => {
    event.preventDefault()
    if (editingCustomChoice) {
      return
    }
    const formData = new FormData(event.target)
    onComplete(formData.getAll(slug))
  }

  const handlePagination = (e) => {
    e.preventDefault()
    renderPage(page + 1)
  }

  const handleSelection = (choice) => {
    let newSelectedChoices = []
    if (selectedChoices.includes(choice)) {
      newSelectedChoices = selectedChoices.filter(s => s != choice)
    } else {
      newSelectedChoices = [...selectedChoices, choice]
    }

    setSelectedChoices(newSelectedChoices)
    onChange(newSelectedChoices)

    if (newSelectedChoices.length == choices.length) {
      onComplete(newSelectedChoices)
    }
  }

  const handleAddCustomChoice = (choice) => {
    if (!choices.includes(choice) && !customChoices.includes(choice)) {
      setCustomChoices([...customChoices, choice])
    }
    handleSelection(choice)
  }

  const handleFocusCustomChoice = () => {
    setEditingCustomChoice(true)
  }

  const handleBlurCustomChoice = () => {
    setEditingCustomChoice(false)
  }

  const renderPage = useCallback((nextPage) => {
    const newChoices = choices.slice(0, (nextPage * limit) + limit)
    setVisibleChoices(newChoices)
    setPage(nextPage)
  }, [choices, limit])

  return <form onSubmit={onSubmit}>
    {[...visibleChoices, ...customChoices].map((choice, i) => (
      <span key={choice}>
        <ChoiceListItem>
          <input type='checkbox'
            name={slug}
            id={`${slug}-${choice}`}
            value={choice}
            checked={selectedChoices.includes(choice)}
            onChange={() => handleSelection(choice)}
          />
          <ChoiceLabel htmlFor={`${slug}-${choice}`}>{choice}</ChoiceLabel>
        </ChoiceListItem>
        {visibleChoices.length > (i + 1) && (i + 1) % limit == 0 && <VerticalSpaceBetweenPages />}
      </span>
    ))}
    {allowCustomText &&
      <CustomChoice key={visibleChoices.length}
        onAdd={handleAddCustomChoice}
        onFocus={handleFocusCustomChoice}
        onBlur={handleBlurCustomChoice}
      />
    }
    {showPagination &&
      <ShowMore type='button' onClick={handlePagination}>Show {Math.min(remaining, limit)} more options...</ShowMore>
    }
    {isActiveQuestion && required &&
      <ButtonContainer visible={isActiveQuestion && selectedChoices.length > 0}>
        <NextButton type='submit' value='Next →' />
      </ButtonContainer>
    }
    {isActiveQuestion && !required &&
      <ButtonContainer visible={true}>
        <NextButton type='submit' value={selectedChoices.length > 0 ? 'Next →' : 'Skip →'} />
      </ButtonContainer>
    }
    <SubmitByPressingEnterBehavior />
  </form>
}

const SelectOneForm = ({ slug, question }) => {
  const answers = useRecoilValue(answersState)
  const answerQuestion = useSurvey()

  return question.choices(answers).map(choice => {
    const id = `${slug}-${choice}`
    return <ChoiceListItem key={choice}>
      <input
        type='radio'
        name={slug}
        value={choice}
        id={id}
        checked={answers[slug] && answers[slug].includes(choice)}
        onChange={({ target }) => {
          answerQuestion(slug, target.value)
        }}
      />
      <ChoiceLabel htmlFor={id}>{choice}</ChoiceLabel>
    </ChoiceListItem>
  })
}

const MoneyForm = ({ slug, type, question }) => {
  const [isValid, setIsValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const formRef = useRef(null)
  const answers = useRecoilValue(answersState)
  const answerQuestion = useSurvey()

  const updateAnswer = (formData) => {
    // strip commas and non-numeric input
    let money = parseInt(formData.get('money').replace(',', ''))

    // convert annual salary to hourly rate
    if (formData.get('period') == 'salary') {
      money /= 1000
      money /= 2
    }

    if (isNaN(money) || money < question.minimumRate) {
      setIsValid(false)
      setErrorMessage(`You must enter at least $${question.minimumRate * 2},000/year or $${question.minimumRate}/hour`)
    } else {
      setIsValid(true)
      answerQuestion(slug, money)
    }
  }

  const handleAnswer = (event) => {
    event.preventDefault()
    updateAnswer(new FormData(event.target))
  }

  const submitForm = (event) => {
    event.preventDefault()
    updateAnswer(new FormData(formRef.current))
  }

  return <>
    {!isValid && <InvalidMoneyErrorMessage>{errorMessage}</InvalidMoneyErrorMessage>}
    <Flex as='form' ref={formRef} name={slug} onSubmit={handleAnswer}>
      <MoneyInputContainer className={!isValid && 'invalid'}>
        <MoneyTextInputPrefix>$</MoneyTextInputPrefix>
        <MoneyTextInput
          name={slug}
          placeholder={question.placeholder}
          autoComplete={type}
          defaultValue={answers[slug]}
          className='autofocus'
          onFocus={e => e.currentTarget.select()}
          onBlur={submitForm}
        />
        <MoneyTextSelectContainer>
          <MoneyTextSelect
            name={'period'}
            defaultValue={answers.availability.includes('full-time') ? 'salary' : 'hourly'}
            onChange={submitForm}
            onBlur={submitForm}
          >
            {answers.availability.includes('full-time') &&
              <option value={'salary'}>Salary</option>
            }
            {(answers.availability.includes('part-time') ||
              answers.availability.includes('project-based') ||
              answers.availability.includes('flexible')) &&
              <option value={'hourly'}>Hourly</option>
            }
          </MoneyTextSelect>
        </MoneyTextSelectContainer>
      </MoneyInputContainer>
      <SubmitByPressingEnterBehavior />
      <Button type='submit' value='Save' />
    </Flex>
  </>
}

const TextForm = ({ slug, type, question }) => {
  const setAnswers = useSetRecoilState(answersState)
  const answers = useRecoilValue(answersState)
  const answerQuestion = useSurvey()

  const handleAnswer = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    answerQuestion(slug, formData.get(slug))
  }

  return <Flex as='form' name={slug} onSubmit={handleAnswer}>
    <TextInput type={type}
      name={slug}
      placeholder={question.placeholder}
      autoComplete={type}
      defaultValue={answers[slug]}
      className='autofocus'
      onFocus={e => e.currentTarget.select()}
      onChange={e => setAnswers({ ...answers, [slug]: e.target.value })}
    />
    <SubmitByPressingEnterBehavior />
    <Button type='submit' value='Save' />
  </Flex>
}

const SubmitByPressingEnterBehavior = () => {
  return <input type="submit" style={{ display: 'none' }} />
}

const BackButton = ({ slug }) => {
  const regress = useSetRecoilState(scrollUpSelector)
  return <SecondaryAction onClick={() => regress(slug)}>← Back</SecondaryAction>
}

const Progress = ({ slug, total }) => {
  const activeQuestions = useRecoilValue(activeQuestionsState)
  const questionsOrder = useRecoilValue(questionsOrderState)
  const current = questionsOrder.indexOf(slug) + 1
  const percent = current / total * 100

  return <ProgressContainer isActiveQuestion={activeQuestions.slice(-1) == slug}>
    <span>{current} of {total}</span>
    <div className="line">
      <div className="completed" style={{ width: `${percent}%` }} />
      <div className="remaining" />
    </div>
  </ProgressContainer>
}

const CustomChoice = ({ onAdd, onFocus, onBlur }) => {
  const [active, setActive] = useState(false)

  const handleKeyUp = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.target.value.length > 0 && e.key === 'Enter') {
      onAdd(e.target.value)
      e.target.value = ''
    }
  }

  return <>
    {!active &&
      <ChoiceLabel onClick={() => setActive(true)}>+ add</ChoiceLabel>
    }
    {active &&
      <CustomChoiceTextInput type="text"
        autoFocus
        placeholder="something else..."
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyUp={handleKeyUp} />
    }
  </>
}
