import styled, { keyframes } from 'styled-components'
import { TextInput, breakpoints, verticalMargins, verticalOffsets, marginBetweenChoicesAndButtons } from './shared.css'

export const QuestionList = styled.ul`
  font-size: 42px;

  padding: ${props => {
    switch (props.pageName) {
      case 'home': return '80px 0 40vh'
      case 'search': return '40px 0 40vh'
      case 'profile': return '40px 0 40vh'
      case 'quiz': return '40px 0 40vh'
      default: return `calc(${verticalMargins.large} + ${verticalOffsets.large}) 0 40vh`
    }
  }};

  @media (max-width: ${breakpoints.small}) {
    padding: ${props => {
    switch (props.pageName) {
      case 'home': return '40px 0 40vh'
      case 'search': return '10px 0 40vh'
      case 'profile': return '10px 0 40vh'
      case 'quiz': return '10px 0 40vh'
      default: return `calc(${verticalMargins.small} + ${verticalOffsets.small}) 0 40vh`
    }
  }};
  }
`

export const QuestionListItem = styled.li`
  margin: 0 0 ${verticalMargins.large};

  @media (max-width: ${breakpoints.small}) {
    margin: 0 0 ${verticalMargins.small};
  }
`

export const QuestionPrompt = styled.h3`
  margin-bottom: 25px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 38px;
  line-height: 41px;
  color: #282828;

  @media (max-width: ${breakpoints.small}) {
    font-size: 30px;
    line-height: 34px;
  }
`

export const QuestionNote = styled.p`
  font-size: 14px;
  margin: 12px 0 0;
`

export const ChoiceListItem = styled.li`
  display: inline-block;
  margin: 0 6px 6px 0;

  input {
    display: none;
  }

  input:checked + label {
    background: #39DCBF;

    @media (max-width: ${breakpoints.small}) and (prefers-color-scheme: dark) {
        background: #147564;
    }
  }

  @media (max-width: ${breakpoints.small}) {
    margin: 0 4px 4px 0;
  }
`

export const ChoiceLabel = styled.label`
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  text-transform: lowercase;
  background: black;
  border-radius: 10px;
  padding: 18px 32px;
  color: #F6F8F7;
  display: inline-block;
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
  cursor: pointer;
  caret-color: transparent;
  transition: all 0.2s ease-in-out;
  user-select: none;

  :hover {
    background-color: #333;
  }

  @media (max-width: ${breakpoints.small}) {
    padding: 15px 25px;
  }
`

export const ButtonContainer = styled.div`
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;

  ${({ visible }) => visible && `
    visibility: visible;
    opacity: 1;
  `}
`

export const ShowMore = styled.button`
  appearance: none;
  border: none;
  background: none;
  font-family: inherit;
  margin: 0;
  cursor: pointer;
  font-size: 13px;
  letter-spacing: 1px;
  color: #4888E8;
  transition: all 0.2s ease-in-out;
  display: block;
  border: 1px solid #4888E8;
  padding: 16px 18px;
  border-radius: 10px;
  margin: 12px 0 0;

  :hover {
    color: #1960ca;
    background: #4888E8;
    color: #fff;
  }

  @media (max-width: ${breakpoints.small}) and (prefers-color-scheme: dark) {
    color: #0c2f65
  }
`

export const SecondaryAction = styled.a`
  font-size: 14px;
  line-height: 39px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #878B8B;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: ${parseInt(marginBetweenChoicesAndButtons.large) + 5}px;
  display: inline-block;

  :hover {
    color: #222;
  }
`

export const SecondaryActionEmphasized = styled(SecondaryAction)`
  color: #4888e8;
  font-weight: 700;
  letter-spacing: normal;

  :hover {
    color: #1960ca;
  }
`

const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const ProgressContainer = styled.div`
  margin-bottom: 16px;
  transition: opacity .25s ease-in-out;
  animation: ${FadeIn} 0.8s ease-in-out;
  opacity: ${props => {
    if (props.isActiveQuestion) {
      return '1'
    } else {
      return '0'
    }
  }};

  span {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 12px;
    line-height: 35px;
    font-weight: 800;
    color: #999;
  }

  .line {
    height: 1px;
    position: relative;
    width: 100%;

    .completed {
      transition: width 2s ease-in-out;
      height: inherit;
      background: #282828;
      position: absolute;
    }

    .remaining {
      height: inherit;
      background: #ddd;
      width: 100%;
    }
  }
`

export const VerticalSpaceBetweenPages = styled.div`
  height: 1px;
  margin: 10px 0 14px;
  background: #ddd;
`

export const CustomChoiceTextInput = styled.input`
  height: 50px;
  margin-left: 8px;
  outline: none;
  border: none;
  border-radius: 0;
  border-bottom: 4px solid black;
  background: transparent;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  text-transform: lowercase;
  color: #222;
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;

  ::placeholder {
    color: #666;
  }
`

export const MoneyInputContainer = styled.div`
  flex-grow: 1;

  &.invalid {
    padding: 12px;
    background: #ffe4e4;
    border-radius: 4px;
    margin-right: 8px;

    input, span, select {
      border-color: #f1828d;
    }
  }
`

export const MoneyTextInput = styled(TextInput)`
  vertical-align: top;
  border-radius: 0;
  width: 300px;
  margin: 0;
  background: #fff;
  padding: 16.5px 16px 17px;

  @media (max-width: ${breakpoints.small}) {
    width: 40%;
  }
`
export const MoneyTextInputPrefix = styled.span`
  flex-grow: 0;
  border-radius: 4px 0 0 4px;
  border: 1px solid #222;
  border-right: none;
  display: inline-block;
  background: #e9e9ed;
  line-height: 60px;
  font-size: 20px;
  height: 58px;
  padding: 0 10px;
`

export const MoneyTextSelect = styled.select`
  cursor: pointer;
  height: 58px;
  vertical-align: top;
  border-radius: 0 4px 4px 0;
  outline: none;
  border: 1px solid #222;
  border-left: none;
  font-size: 16px;
  text-align: center;
  padding: 0 30px 0 20px;
  margin: 0;
  appearance: none;

  @media (max-width: ${breakpoints.small}) {
    padding: 0 15px 0 10px;
  }
`

export const MoneyTextSelectContainer = styled.div`
  display: inline;
  position: relative;

  &::after {
    content: "▼";
    font-size: 12px;
    top: 26px;
    right: 13px;
    position: absolute;

    @media (max-width: ${breakpoints.small}) {
      right: 6px;
    }
  }
`

export const InvalidMoneyErrorMessage = styled.p`
  font-size: 18px;
  background: white;
  border: 1px solid #f1828d;
  padding: 12px;
  margin: 0 0 16px;
  border-radius: 4px;
`