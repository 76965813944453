import { useRecoilValue, useSetRecoilState } from 'recoil'
import { usePlausible } from 'next-plausible'
import { questionsOrderState, answersState } from '../state/atoms'
import { answersSelector } from '../state/selectors'

export function useValidation() {
  const questionsOrder = useRecoilValue(questionsOrderState)
  const answers = useRecoilValue(answersState)

  function validate() {
    const unanswered = questionsOrder.reduce((acc, slug) => {
      if (!answers[slug] || answers[slug].length == 0) {
        return [...acc, slug]
      } else { return acc }
    }, [])

    return unanswered.length == 0
  }

  return validate
}

export function useSurvey() {
  const plausible = usePlausible()
  const setAnswer = useSetRecoilState(answersSelector)

  function answer(slug, value) {
    setAnswer({ [slug]: value })
    plausible('Answered Question', { props: { slug: slug, answer: value }})
  }

  return answer
}